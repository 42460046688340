<template>
	<b-modal
		id="undo-delete-faq"
		ref="undo-delete-faq-modal"
		title="Undo Delete"
		:no-close-on-backdrop="true"
		@show="onReset"
		@hidden="onReset"
		size="md"
	>
		<div class="row">
			<div class="col-md-12">
				<h4 class="mb-4">Undo delete for this FAQ?</h4>
				<p><strong>Question</strong>: {{ form.question }}</p>
				<p><strong>Created By</strong>: {{ form.createdBy }}</p>
				<p>
					<strong>Date Created</strong>:
					{{ form.dateCreated | toDateTimeString }}
				</p>
				<p>
					<strong>Date Deleted</strong>:
					{{ form.dateDeleted | toDateTimeString }}
				</p>
			</div>
		</div>

		<template v-slot:modal-footer>
			<div class="w-100 text-right">
				<b-button
					size="sm"
					variant="success"
					class="mr-2"
					@click.prevent="submit"
				>
					Undo Delete
				</b-button>
			</div>
		</template>
	</b-modal>
</template>

<script>
// API and DAO
import faqsDAO from '../../../database/faqs';

// Util
import { DateUtil } from '../../../utils/dateutil';

// Others
import EventBus from '@/shared/event-bus';

export default {
	name: 'delete-faq',
	data() {
		return {
			form: {
				id: '',
				question: '',
				answer: '',
				createdBy: '',
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: '',
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			},
		};
	},
	mounted() {
		EventBus.$on('showUndoDeleteFaqDialog', (item) => {
			this.$bvModal.show('undo-delete-faq');
			this.form = item;
		});
	},
	methods: {
		async submit() {
			try {
				await faqsDAO.undoDelete(this.form.id);

				this.$toaster.success('FAQ has been activated');
				this.$bvModal.hide('undo-delete-faq');
				EventBus.$emit('onSaveFAQ');
			} catch (_error) {
				this.$toaster.error('Error undoing the delete of FAQ.');
			}
		},
		onReset() {
			this.form = {
				id: '',
				question: '',
				answer: '',
				createdBy: this.loggedUser.id,
				dateCreated: DateUtil.getCurrentTimestamp(),
				updatedBy: this.loggedUser.id,
				dateUpdated: DateUtil.getCurrentTimestamp(),
				deletedBy: '',
				dateDeleted: null,
				isDeleted: false,
			};
		},
	},
	beforeDestroy() {
		EventBus.$off('showUndoDeleteFaqDialog');
		this.onReset();
	},
};
</script>