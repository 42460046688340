<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-question"></i> Frequently Asked Questions</b-card-title>
			<b-card-sub-title>Manages customer's FAQs</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Select Actions and Items Per Page Options -->
				<b-row>
					<b-col sm="6" md="3" class="mb-2">
						<b-dropdown text=" Select Actions " variant="dark">
							<b-dropdown-item @click="
								eventBus.$emit(
									'showAddFaqDialog',
									items.map((item) => item.question)
								)
								">
								<i class="fa fa-plus"></i>&nbsp; Add New
							</b-dropdown-item>
							<b-dropdown-item @click="eventBus.$emit('showFAQsReorderingDialog', items)">
								<i class="fa fa-list"></i>&nbsp; Re-order FAQs
							</b-dropdown-item>
						</b-dropdown>
					</b-col>
					<b-col sm="6" md="3" offset-md="6" class="mb-2 text-md-right">
						<b-input-group prepend="Show" append="/ Page">
							<b-form-select :options="pageOptions" v-model="perPage" />
						</b-input-group>
					</b-col>
				</b-row>

				<b-table :items="items" :fields="fields" :responsive="true" selected-variant="primary"
					:current-page="currentPage" :per-page="perPage" show-empty>
					<template v-slot:cell(question)="row">
						<span class="text-nowrap">{{ row.item.question }}</span>
					</template>
					<template v-slot:cell(dateCreated)="row">
						<div class="text-nowrap">
							{{ row.item.dateCreated | toDateTimeString }}
						</div>
					</template>
					<template v-slot:cell(isDeleted)="row">
						<div class="text-nowrap">
							<b-badge class="p-1" :variant="`${row.item.isDeleted ? 'danger' : 'success'}`">
								{{ row.item.isDeleted ? 'YES' : 'NO' }}
							</b-badge>
						</div>
					</template>
					<template v-slot:cell(actions)="row">
						<div class="text-nowrap">
							<b-button size="sm" variant="dark" class="mr-1" @click="row.toggleDetails">
								<i :class="`fa fa-eye${row.detailsShowing ? '-slash' : ''}`"></i>
							</b-button>
							<b-button size="sm" variant="warning" class="mr-1"
								@click="eventBus.$emit('showUpdateFaqDialog', row.item)">
								<i class="fa fa-pencil"></i>
							</b-button>
							<b-button size="sm" variant="danger" class="mr-1"
								@click="eventBus.$emit('showDeleteFaqDialog', row.item)">
								<i class="fa fa-trash"></i>
							</b-button>
							<b-button v-if="row.item.isDeleted" size="sm" variant="success"
								v-b-tooltip.hover.top="'Undo Delete'"
								@click="eventBus.$emit('showUndoDeleteFaqDialog', row.item)">
								<i class="fa fa-undo"></i>
							</b-button>
						</div>
					</template>
					<template v-slot:row-details="row">
						<b-card>
							<b-row>
								<b-col>
									<h4>Answer</h4>
									<span v-html="row.item.answer"></span>
								</b-col>
							</b-row>
							<b-row>
								<b-col class="text-right">
									<b-button size="sm" @click="row.toggleDetails">
										<i class="fa fa-arrow-up"></i>
									</b-button>
								</b-col>
							</b-row>
						</b-card>
					</template>
				</b-table>

				<b-row>
					<b-col md="8" sm="12" class="my-1">
						<span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
					</b-col>
					<b-col md="4" sm="12" class="my-1">
						<b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
							class="my-0" />
					</b-col>
				</b-row>
			</div>			
		</b-card>

		<!-- Modals -->
		<AddFaq />
		<UpdateFaq />
		<DeleteFaq />
		<UndoDeleteFaq />
		<ReorderFaqs />
	</div>
</template>

<script>
// Components
import AddFaq from './faq/AddFaq';
import UpdateFaq from './faq/UpdateFaq';
import DeleteFaq from './faq/DeleteFaq';
import UndoDeleteFaq from './faq/UndoDeleteFaq';
import ReorderFaqs from './faq/ReorderFaqs';

// DAO
import faqsDAO from '../../database/faqs';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import EventBus from '@/shared/event-bus';
import _ from 'lodash';


export default {
	name: 'faqs',
	components: {
		AddFaq,
		UpdateFaq,
		DeleteFaq,
		UndoDeleteFaq,
		ReorderFaqs,
		Loading,
	},
	data() {
		return {
			eventBus: EventBus,
			isLoading: false,
			items: [],
			fields: [
				{ key: 'question', label: 'Question', sortable: 'true' },
				{ key: 'createdBy', label: 'Created By' },
				{ key: 'isDeleted', label: 'Is Deleted?' },
				{
                    key: 'actions',
                    thClass: 'text-center'
                }
			],
			currentPage: 1,
			perPage: 15,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
		};
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (!this.$store.getters.isSuperAdmin) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// Show loader
				this.isLoading = true;

				await this.retrieveFaqs();

			} catch(error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onSaveFAQ', () => this.retrieveFaqs());
	},
	methods: {
		async retrieveFaqs() {		
			try {
				// show loading indicator
				this.isLoading = true;

				const faqsObj = await faqsDAO.retrieve(this.filterBy);
				this.items = Object.values(faqsObj);
				this.totalRows = _.size(this.items);
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		onFilterRequest() {
			this.retrieveFaqs();
		},
		resetFilters() {
			this.retrieveFaqs();
		},
	},
	beforeDestroy() {
		EventBus.$off('onSaveFAQ');
	},
};
</script>